import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from '../globals';
import { PostsService } from '../service/posts.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private postsService: PostsService,
    public globals: Globals
  ) {}

  lastThreePosts: any;

  ngOnInit(): void {
    this.getLastThreePosts();
  }

  getLastThreePosts() {
    this.postsService.getUrlLastThreePost().subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.lastThreePosts = response['body']['hydra:member'];
          console.log(this.lastThreePosts);
        } else {
          console.log('Erreur : ', response.status);
        }
      },
      (error) => console.log(error)
    );
  }
}
