<!-- NAVBAR -->
<nav class="navbar navbar-expand-lg navbar-dark navbar-togglable fixed-top">
    <div class=" container-fluid">
        <!-- Brand -->
        <a class="navbar-brand" href="./index.html">
            <img src="./assets/img/origin/logo_large.png" class="navbar-brand-img" alt="..." />
        </a>

        <!-- Toggler -->
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <!-- Collapse -->
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <!-- Toggler -->
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" aria-controls="navbarCollapse"
                aria-expanded="false" aria-label="Toggle navigation" data-bs-target=".navbar-collapse.show">
                <i class="fe fe-x"></i>
            </button>

            <!-- Navigation -->
            <ul class="navbar-nav ms-auto">
                <li class="nav-item">
                    <a routerLink="" class="nav-link" id="navbarLandings" data-bs-toggle="collapse" aria-haspopup="true"
                        aria-expanded="false" data-bs-target=".navbar-collapse.show">
                        Accueil

                    </a>
                </li>

                <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" id="navbarPages" data-bs-toggle="dropdown"
                        routerLink="programmes" aria-haspopup="true" aria-expanded="false">
                        Nos programmes


                        <i class="fe fe-pocket"></i>


                    </a>
                    <div class="dropdown-menu dropdown-menu-lg" aria-labelledby="navbarPages">
                        <div class="row gx-0">
                            <div class="col-6">
                                <div class="
                    row
                    gx-0
                    d-flex
                    flex-column
                    align-content-center
                    text-center
                  ">
                                    <div class="col-12 col-lg-6">
                                        <!-- Heading -->
                                        <h6 class="dropdown-header">Musculaire</h6>

                                        <!-- List -->
                                        <a class="dropdown-item" routerLink="programmes"
                                            data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">
                                            Novice
                                        </a>
                                        <a class="dropdown-item mb-5" routerLink="programmes"
                                            data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">
                                            Confirmé
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="
                    row
                    gx-0
                    d-flex
                    flex-column
                    align-content-center
                    text-center
                  ">
                                    <div class="col-12 col-lg-6">
                                        <!-- Heading -->
                                        <h6 class="dropdown-header">Nutritionel</h6>

                                        <!-- List -->
                                        <a class="dropdown-item" routerLink="programmes"
                                            data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">
                                            Micro-nutrition
                                        </a>
                                        <a class="dropdown-item mb-5" routerLink="programmes"
                                            data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">
                                            Régime spécifique
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="
                    row
                    gx-0
                    d-flex
                    flex-column
                    align-content-center
                    text-center
                  ">
                                    <div class="col-12 col-lg-12">
                                        <!-- Heading -->
                                        <h6 class="dropdown-header">Programme personnalisé</h6>

                                        <!-- List -->
                                        <a class="dropdown-item" routerLink="programmes"
                                            data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">
                                            Découvrir votre profil type
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- / .row -->
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="navbarLandings" routerLink="articles" aria-haspopup="true"
                        aria-expanded="false" data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">
                        Mes articles
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="navbarLandings" routerLink="transformations" aria-haspopup="true"
                        aria-expanded="false" data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">
                        Avant-Après
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="navbarLandings" routerLink="coach" aria-haspopup="true"
                        aria-expanded="false" data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">
                        Qui suis-je ?
                    </a>
                </li>
            </ul>

            <!-- Button -->
            <a class="navbar-btn btn btn-lg btn-pill btn-primary lift ms-auto" routerLink="contact"
                data-bs-target=".navbar-collapse.show" data-bs-toggle="collapse">
                Me contacter
            </a>
        </div>
    </div>
</nav>