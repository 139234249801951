import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  @ViewChild('navbar') navbar: any;

  hideNavbar() {
    if (this.navbar.shown) {
      this.navbar.hide();
    }
  }
}
