import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ProgramsService {
  constructor(private http: HttpClient) {}
  // API_SERVER = 'https://127.0.0.1:8000/api';
  API_SERVER = 'https://admin.agence-web-rovere.fr/api';

  getUrlAllPrograms() {
    return this.http.get(`${this.API_SERVER}/products?online=true`, {
      observe: 'response',
    });
  }

  getUrlSinglePrograms(id: string) {
    return this.http.get(`${this.API_SERVER}/products/${id}`, {
      observe: 'response',
    });
  }
}
