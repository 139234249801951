<section class="py-10 py-md-14 overlay overlay-black overlay-60 bg-cover"
    style="background-image: url(assets/img/origin/writing.png);">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">

                <!-- Heading -->
                <h1 class="display-2 fw-bold text-white">
                    Mes articles
                </h1>

                <!-- Text -->
                <p class="lead text-white-75 mb-0">
                    Mes conseils physique, santé et autres pensées !
                </p>

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>

<!-- SHAPE -->
<div class="position-relative">
    <div class="shape shape-bottom shape-fluid-x text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
        </svg>
    </div>
</div>
<!-- HEADER -->
<section class="pt-8 pt-md-11">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-9 col-xl-8">

                <!-- Heading -->
                <h1 class="display-4 text-center">
                    {{post?.title}}
                </h1>

                <!-- Text -->
                <p class="lead mb-7 text-center text-muted">
                    {{post?.preview}}
                </p>

                <!-- Meta -->
                <div class="row align-items-center py-5 border-top border-bottom">
                    <div class="col-auto">

                        <!-- Avatar -->
                        <div class="avatar avatar-lg">
                            <img src="assets/img/avatars/avatar-1.jpg" alt="..." class="avatar-img rounded-circle">
                        </div>

                    </div>
                    <div class="col ms-n5">

                        <!-- Name -->
                        <h6 class="text-uppercase mb-0">
                            Charlotte Loussouam
                        </h6>

                        <!-- Date -->
                        <time class="fs-sm text-muted">
                            Publié le {{post?.createdAt | date: 'dd/MM/yyyy'}}
                        </time>

                    </div>
                    <div class="col-auto">

                        <!-- Share -->
                        <span class="h6 text-uppercase text-muted d-none d-md-inline me-4">
                            Catégorie: {{post?.category?.name}}
                        </span>
                    </div>
                </div>

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>

<!-- SECTION -->
<section class="pt-6 pt-md-8">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-9 col-xl-8">

                <!-- Fugure -->
                <figure class="figure mb-7">

                    <!-- Image -->
                    <img class="figure-img img-fluid rounded lift lift-lg" src="{{globals.IMG_URL}}{{post?.file}}"
                        alt="...">

                </figure>
            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>

<!-- SECTION -->
<section class="pt-6 pt-md-8">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-9 col-xl-8">

                <!-- Heading -->
                <h2 class="fw-bold">
                    {{post?.title}}
                </h2>

                <!-- Text -->
                <p [innerHTML]="post?.content">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi reiciendis odio perferendis
                    libero saepe voluptatum fugiat dolore voluptates aut, ut quas doloremque quo ad quis ipsum molestias
                    neque pariatur commodi.
                </p>
            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>







<!-- SECTION -->
<section class="pt-6 pt-md-8 pb-8 pb-md-11">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-9 col-xl-8">

                <!-- Meta -->
                <div class="row align-items-center py-5 border-top border-bottom">
                    <div class="col-auto">

                        <!-- Avatar -->
                        <div class="avatar avatar-lg">
                            <img src="assets/img/avatars/avatar-1.jpg" alt="..." class="avatar-img rounded-circle">
                        </div>

                    </div>
                    <div class="col ms-n5">

                        <!-- Name -->
                        <h6 class="text-uppercase mb-0">
                            Charlotte Louassam
                        </h6>

                        <!-- Date -->
                        <time class="fs-sm text-muted">
                            Publié le {{post?.createdAt | date: 'dd/MM/yyyy'}}
                        </time>

                    </div>
                    <div class="col-auto">

                        <!-- Share -->
                        <span class="h6 text-uppercase text-muted d-none d-md-inline me-4">
                            Partager:
                        </span>

                        <!-- Icons -->
                        <ul class="d-inline list-unstyled list-inline list-social">
                            <li class="list-inline-item list-social-item me-3">
                                <a href="#!" class="text-decoration-none">
                                    <img src="./assets/img/icons/social/instagram.svg" class="list-social-icon"
                                        alt="...">
                                </a>
                            </li>
                            <li class="list-inline-item list-social-item me-3">
                                <a href="#!" class="text-decoration-none">
                                    <img src="./assets/img/icons/social/facebook.svg" class="list-social-icon"
                                        alt="...">
                                </a>
                            </li>
                            <li class="list-inline-item list-social-item me-3">
                                <a href="#!" class="text-decoration-none">
                                    <img src="./assets/img/icons/social/twitter.svg" class="list-social-icon" alt="...">
                                </a>
                            </li>
                        </ul>

                    </div>
                </div>

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>

<!-- SHAPE -->
<div class="position-relative">
    <div class="shape shape-bottom shape-fluid-x text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
        </svg>
    </div>
</div>

<!-- ARTICLES -->
<section class="pt-7 pt-md-10 bg-light">
    <div class="container">
        <div class="row align-items-center mb-5">
            <div class="col-12 col-md">

                <!-- Heading -->
                <h3 class="mb-0">
                    Les derniers articles
                </h3>

                <!-- Text -->
                <p class="mb-0 text-muted">
                    à ne pas manquer !
                </p>

            </div>
            <div class="col-12 col-md-auto">

                <!-- Button -->
                <a routerLink="../../articles" class="btn btn-sm btn-outline-gray-300 d-none d-md-inline">
                    Voir tous
                </a>

            </div>
        </div> <!-- / .row -->
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 d-flex" *ngFor="let post of lastThreePosts">

                <!-- Card -->
                <div class="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg">

                    <!-- Image -->
                    <a class="card-img-top" routerLink="../{{post?.id}}">

                        <!-- Image -->
                        <img src="{{globals.IMG_URL}}{{post.file}}" alt="..." class="card-img-top">

                        <!-- Shape -->
                        <div class="position-relative">
                            <div class="shape shape-bottom shape-fluid-x text-white">
                                <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" fill="currentColor" />
                                </svg>
                            </div>
                        </div>

                    </a>

                    <!-- Body -->
                    <a class="card-body" routerLink="../{{post?.id}}">

                        <!-- Heading -->
                        <h3>
                            {{post?.title}}
                        </h3>

                        <!-- Text -->
                        <p class="mb-0 text-muted">
                            {{post?.preview}}
                        </p>

                    </a>

                    <!-- Meta -->
                    <a class="card-meta mt-auto" routerLink="../{{post?.id}}">

                        <!-- Divider -->
                        <hr class="card-meta-divider">

                        <!-- Avatar -->
                        <div class="avatar avatar-sm me-2">
                            <img src="assets/img/avatars/avatar-1.jpg" alt="..." class="avatar-img rounded-circle">
                        </div>

                        <!-- Author -->
                        <h6 class="text-uppercase text-muted me-2 mb-0">
                            Charlotte Loussouam
                        </h6>

                        <!-- Date -->
                        <p class="h6 text-uppercase text-muted mb-0 ms-auto">
                            <time>{{post?.createdAt | date: 'dd/MM/yyyy'}}</time>
                        </p>

                    </a>

                </div>

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>