import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  constructor(private http: HttpClient) {}
  // API_SERVER = 'https://127.0.0.1:8000/api';
  API_SERVER = 'https://admin.agence-web-rovere.fr/api';

  getUrlAllPosts() {
    return this.http.get(`${this.API_SERVER}/posts?online=true`, {
      observe: 'response',
    });
  }

  getUrlSinglePost(id: string) {
    return this.http.get(`${this.API_SERVER}/posts/${id}`, {
      observe: 'response',
    });
  }

  getUrlLastThreePost() {
    return this.http.get(`${this.API_SERVER}/posts/lastThree?online=true`, {
      observe: 'response',
    });
  }

  getNewUrlPosts(page: any) {
    return this.http.get(`${this.API_SERVER}/posts?page=${page}`, {
      observe: 'response',
    });
  }
}
