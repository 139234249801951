import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BeforeAfterComponent } from './before-after/before-after.component';
import { ContactComponent } from './contact/contact.component';
import { FourOhFourComponent } from './four-oh-four/four-oh-four.component';
import { HomeComponent } from './home/home.component';
import { PostDetailComponent } from './posts/post-detail/post-detail.component';
import { PostsComponent } from './posts/posts.component';
import { ProgramsComponent } from './programs/programs.component';
import { WhoIAmComponent } from './who-iam/who-iam.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'programmes',
    component: ProgramsComponent,
  },
  {
    path: 'articles',
    component: PostsComponent,
  },
  {
    path: 'articles/:id',
    component: PostDetailComponent,
  },
  {
    path: 'transformations',
    component: BeforeAfterComponent,
  },
  {
    path: 'coach',
    component: WhoIAmComponent,
  },
  {
    path: 'not-found',
    component: FourOhFourComponent,
  },
  {
    path: '',
    component: HomeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
