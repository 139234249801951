import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Globals } from 'src/app/globals';
import { PostsService } from 'src/app/service/posts.service';

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss'],
})
export class PostDetailComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private postsService: PostsService,
    public globals: Globals
  ) {}

  post: any;
  lastThreePosts: any;

  ngOnInit(): void {
    this.getLastThreePosts();
    // recupération de l'ID grâce à l'adresse URL fais à l'initialisation du component.
    // L'ID sera transmis à getPost
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');
      if (id) {
        this.getPost(id);
      }
    });
  }
  // méthode permettant d'affiche le post correspondant à l'Id en appelant une méthode du service posts
  getPost(id: string) {
    this.postsService.getUrlSinglePost(id).subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.post = response['body'];
          console.log(this.post);
        } else {
          console.log('Erreur : ', response.status);
        }
      },
      (error) => console.log(error)
    );
  }

  getLastThreePosts() {
    this.postsService.getUrlLastThreePost().subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.lastThreePosts = response['body']['hydra:member'];
          console.log(this.lastThreePosts);
        } else {
          console.log('Erreur : ', response.status);
        }
      },
      (error) => console.log(error)
    );
  }
}
