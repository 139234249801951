<section class="py-10 py-md-14 overlay overlay-black overlay-60 bg-cover"
    style="background-image: url(assets/img/origin/imageAccueil.png);">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-9 text-center">

                <!-- Heading -->
                <h1 class="display-2 fw-bold text-white">
                    Transformations
                </h1>

                <!-- Text -->
                <p class="lead text-white-75 mb-0">
                    Se convaincre que tu en es capabable, c'est déjà la moitié du chemin
                </p>

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>

<!-- SHAPE -->
<div class="position-relative">
    <div class="shape shape-bottom shape-fluid-x text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
        </svg>
    </div>
</div>

<section class="pt-8 pt-md-11 pb-8 pb-md-14">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">

                <!-- Headin -->
                <h1 class="display-2">
                    Ils ont réalisé leurs objectifs
                </h1>

                <!-- Text -->
                <p class="lead text-muted mb-7 mb-md-9">
                    Ce mode de vie est une passion pour moi et pouvoir la partager
                </p>

            </div>
        </div> <!-- / .row -->

        <div data-flickity='{"pageDots": false,"cellAlign": "left", "wrapAround": true, "imagesLoaded": true}'>
            <div class="col-12 col-md-4 px-1">
                <img src="../assets/img/origin/avant1.png" class="img-fluid" alt="...">
            </div>
            <div class="col-12 col-md-4 px-1">
                <img src="../assets/img/origin/avant2.png" class="img-fluid" alt="...">
            </div>
            <div class="col-12 col-md-4 px-1">
                <img src="../assets/img/origin/avant3.png" class="img-fluid" alt="...">
            </div>
            <div class="col-12 col-md-4 px-1">
                <img src="../assets/img/origin/avant4.png" class="img-fluid" alt="...">
            </div>
        </div>

    </div>
    <div class="container">
        <div class="row justify-content-center">
            <p class="lead text-center">Et vous ?</p>

            <!-- Button -->
            <a routerLink="../programmes" class="btn btn-primary shadow lift">
                Découvrir votre programme
            </a>
        </div>
    </div>
</section>