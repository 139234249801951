<section class="py-10 py-md-14 overlay overlay-black overlay-60 bg-cover"
    style="background-image: url(assets/img/origin/writing.png);">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">

                <!-- Heading -->
                <h1 class="display-2 fw-bold text-white">
                    Mes articles
                </h1>

                <!-- Text -->
                <p class="lead text-white-75 mb-0">
                    Mes conseils physique, santé et autres pensées !
                </p>

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>

<!-- SHAPE -->
<div class="position-relative">
    <div class="shape shape-bottom shape-fluid-x text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
        </svg>
    </div>
</div>
<!-- ARTICLES -->
<section class="pt-8 pt-md-11">
    <div class="container">

        <div class="row">
            <div class="col-12">

                <!-- Heading -->
                <h3 class="mb-0">
                    Tous mes articles
                </h3>

                <!-- Text -->
                <p class="mb-5 text-muted">
                    Suivez mon actualitées !
                </p>
            </div>
        </div> <!-- / .row -->

        <div class="beforeContent" *ngIf="posts == null">
        </div>

        <div class="row">
            <pagination-controls *ngIf="totalItems > 10" class="pagi" (pageChange)="getNewUrl((page = $event))"
                previousLabel="Précedent" nextLabel="Suivant">
            </pagination-controls>
            <div class="col-12" *ngFor="let post of posts  | paginate
            : {
                itemsPerPage: itemsPerPage,
                currentPage: page,
                totalItems: totalItems
              } ; let i = index">

                <!-- Card -->
                <div class="card card-row shadow-light-lg mb-6" *ngIf="i >= 0 && i%2 === 0">
                    <div class=" row gx-0">
                        <div class="col-12">

                            <!-- Badge -->
                            <span class="badge rounded-pill bg-gray-600 badge-float badge-float-outside">
                                <span class="h6 text-uppercase">{{post.category?.name}}</span>
                            </span>
                        </div>
                        <a class="col-12 col-md-6 order-md-2 bg-cover card-img-end background-img-contain" style="background: url('{{globals.IMG_URL}}{{post.file}}');
                            " routerLink="{{post?.id}}">

                            <!-- Image (placeholder) -->
                            <img src="{{globals.IMG_URL}}{{post?.file}}" alt="..."
                                class="img-fluid d-md-none invisible">

                            <!-- Shape -->
                            <div class="shape shape-start shape-fluid-y text-white d-none d-md-block">
                                <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z" fill="currentColor" />
                                </svg>
                            </div>

                        </a>
                        <div class="col-12 col-md-6 order-md-1">

                            <!-- Body -->
                            <a class="card-body" routerLink="{{post?.id}}">

                                <!-- Heading -->
                                <h3>
                                    {{post?.title}}
                                </h3>

                                <!-- Text -->
                                <p class="mb-0 text-muted">
                                    {{post?.preview}}
                                </p>

                            </a>

                            <!-- Meta -->
                            <a class="card-meta" routerLink="{{post?.id}}">

                                <!-- Divider -->
                                <hr class="card-meta-divider">

                                <!-- Avatar -->
                                <div class="avatar avatar-sm me-2">
                                    <img src="assets/img/avatars/avatar-1.jpg" alt="..."
                                        class="avatar-img rounded-circle">
                                </div>

                                <!-- Author -->
                                <h6 class="text-uppercase text-muted me-2 mb-0">
                                    Charlotte Loussouam
                                </h6>

                                <!-- Date -->
                                <p class="h6 text-uppercase text-muted mb-0 ms-auto">
                                    <time>{{post?.createdAt | date: 'dd/MM/yyyy'}}</time>
                                </p>

                            </a>

                        </div>

                    </div> <!-- / .row -->
                </div>

                <!-- Card -->
                <div class="card card-row shadow-light-lg mb-6" *ngIf="i >= 0 && i%2 === 1">
                    <div class="row gx-0">
                        <div class="col-12">

                            <!-- Badge -->
                            <span class="badge rounded-pill bg-gray-600 badge-float badge-float-outside">
                                <span class="h6 text-uppercase">{{post.category?.name}}</span>
                            </span>

                        </div>
                        <a class="col-12 col-md-6 bg-cover card-img-start background-img-contain" style="background: url('{{globals.IMG_URL}}{{post.file}}');
                            " routerLink="{{post?.id}}">

                            <!-- Image (placeholder) -->
                            <img src="assets/img/photos/photo-28.jpg" alt="..." class="img-fluid d-md-none invisible">

                            <!-- Shape -->
                            <div class="shape shape-end shape-fluid-y text-white d-none d-md-block">
                                <svg viewBox="0 0 112 690" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M116 0H51v172C76 384 0 517 0 517v173h116V0z" fill="currentColor" />
                                </svg>
                            </div>

                        </a>
                        <div class="col-12 col-md-6">

                            <!-- Body -->
                            <a class="card-body" routerLink="{{post?.id}}">

                                <!-- Heading -->
                                <h3>
                                    {{post?.title}}
                                </h3>

                                <!-- Text -->
                                <p class="mb-0 text-muted">
                                    {{post?.content}}
                                </p>

                            </a>

                            <!-- Meta -->
                            <a class="card-meta" routerLink="{{post?.id}}">

                                <!-- Divider -->
                                <hr class="card-meta-divider">

                                <!-- Avatar -->
                                <div class="avatar avatar-sm me-2">
                                    <img src="assets/img/avatars/avatar-2.jpg" alt="..."
                                        class="avatar-img rounded-circle">
                                </div>

                                <!-- Author -->
                                <h6 class="text-uppercase text-muted me-2 mb-0">
                                    Charlotte Loussouam
                                </h6>

                                <!-- Date -->
                                <p class="h6 text-uppercase text-muted mb-0 ms-auto">
                                    <time>{{post?.createdAt | date: 'dd/MM/yyyy'}}</time>
                                </p>

                            </a>

                        </div>
                    </div>

                </div>
            </div>
            <pagination-controls *ngIf="totalItems > 10" class="pagi" (pageChange)="getNewUrl((page = $event))"
                previousLabel="Précedent" nextLabel="Suivant">
            </pagination-controls>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>