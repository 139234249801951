import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { ProgramsService } from '../service/programs.service';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss'],
})
export class ProgramsComponent implements OnInit {
  constructor(
    private programsService: ProgramsService,
    public globals: Globals
  ) {}

  programs: any;

  ngOnInit(): void {
    this.getAllProducts();
  }

  getAllProducts() {
    this.programsService.getUrlAllPrograms().subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.programs = response['body']['hydra:member'];
          console.log(this.programs);
        } else {
          console.log('Erreur : ', response.status);
        }
      },
      (error) => console.log(error)
    );
  }
}
