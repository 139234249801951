import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Globals } from '../globals';
import { PostsService } from '../service/posts.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss'],
})
export class PostsComponent implements OnInit {
  constructor(
    private postsService: PostsService,
    public globals: Globals,
    private viewPortScroll: ViewportScroller
  ) {}

  posts: any;
  page: number = 1;
  totalItems: any;
  itemsPerPage: number = 10;

  ngOnInit(): void {
    this.getAllPosts();
  }

  getAllPosts() {
    this.postsService.getUrlAllPosts().subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.posts = response['body']['hydra:member'];
          this.totalItems = response['body']['hydra:totalItems'];
        } else {
          console.log('Erreur : ', response.status);
        }
      },
      (error) => console.log(error)
    );
  }

  getNewUrl(page: any) {
    this.postsService.getNewUrlPosts(page).subscribe(
      (response: any) => {
        if (response.status === 200) {
          this.posts = response['body']['hydra:member'];
          this.viewPortScroll.scrollToPosition([0, 0]);
        } else {
          console.log('Erreur : ', response.status);
        }
      },
      (error) => console.log(error)
    );
  }
}
