import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, SafePipe, TruncatePipe } from './app.component';
import { MenuComponent } from './menu/menu.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { FooterComponent } from './footer/footer.component';
import { BeforeAfterComponent } from './before-after/before-after.component';
import { WhoIAmComponent } from './who-iam/who-iam.component';
import { PostsComponent } from './posts/posts.component';
import { ProgramsComponent } from './programs/programs.component';
import { FourOhFourComponent } from './four-oh-four/four-oh-four.component';
import { PostDetailComponent } from './posts/post-detail/post-detail.component';
import { HttpClientModule } from '@angular/common/http';
import { PostsService } from './service/posts.service';
import { ProgramsService } from './service/programs.service';
import { Globals } from './globals';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailService } from './service/email.service';

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomeComponent,
    ContactComponent,
    FooterComponent,
    BeforeAfterComponent,
    WhoIAmComponent,
    PostsComponent,
    ProgramsComponent,
    FourOhFourComponent,
    PostDetailComponent,
    TruncatePipe,
    SafePipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [PostsService, ProgramsService, EmailService, Globals],
  bootstrap: [AppComponent],
})
export class AppModule {}
