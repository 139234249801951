<section data-jarallax data-speed=".8" class="pt-12 pb-10 pt-md-15 pb-md-14"
    style="background-image: url(assets/img/origin/femme.png)">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-md-8 col-lg-6">

                <!-- Heading -->
                <h1 class="display-3 fw-bold text-white" id="welcomeHeadingSource">
                    Mes programmes personnalisés pour <br />
                    <span class="text-primary"
                        data-typed='{"strings": ["Body-builder", "Fit-girl", "Abdos", "Fessiers", "Pecs", "Epaules", "Quadri", "Ischios-fessier", "Dos", "Et bien plus encore !" ]}'></span>
                </h1>


                <!-- Text -->
                <p class="fs-lg text-white-80 mb-6">
                    Sportive de haut niveau, la clé de mon succès repose sur l’alliance d’une préparation physique
                    adapté à une alimentation spécifique
                </p>


                <!-- Button -->
                <a routerLink="programmes" class="btn btn-primary shadow lift">
                    Découvrir votre programme
                </a>


            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>

<!-- BRANDS -->
<section class="py-6 py-md-8 bg-secondary">
    <div class="container">
        <div class="flickity-viewport-visible"
            data-flickity='{"imagesLoaded": true, "initialIndex": 6, "pageDots": false, "prevNextButtons": false, "contain": true}'>
            <div class="col-4 col-md-3 col-lg-2">

                <!-- Brand -->
                <div class="img-fluid text-dark">
                    <img src="../../assets/img/svg/fitness-park.svg">
                </div>

            </div>
            <div class="col-4 col-md-3 col-lg-2">

                <!-- Brand -->
                <div class="img-fluid text-dark">
                    <img src="../../assets/img/svg/fit-life.svg">
                </div>

            </div>
            <div class="col-4 col-md-3 col-lg-2">

                <!-- Brand -->
                <div class="img-fluid text-dark">
                    <img src="../../assets/img/svg/venice.svg">
                </div>

            </div>
            <div class="col-4 col-md-3 col-lg-2">

                <!-- Brand -->
                <div class="img-fluid text-dark">
                    <img src="../../assets/img/svg/fitness-park.svg">
                </div>

            </div>
            <div class="col-4 col-md-3 col-lg-2">

                <!-- Brand -->
                <div class="img-fluid text-dark">
                    <img src="../../assets/img/svg/fit-life.svg">
                </div>

            </div>
            <div class="col-4 col-md-3 col-lg-2">

                <!-- Brand -->
                <div class="img-fluid text-dark">
                    <img src="../../assets/img/svg/venice.svg">
                </div>

            </div>
            <div class="col-4 col-md-3 col-lg-2">

                <!-- Brand -->
                <div class="img-fluid text-dark">
                    <img src="../../assets/img/svg/fitness-park.svg">
                </div>

            </div>
            <div class="col-4 col-md-3 col-lg-2">

                <!-- Brand -->
                <div class="img-fluid text-dark">
                    <img src="../../assets/img/svg/fit-life.svg">
                </div>

            </div>
            <div class="col-4 col-md-3 col-lg-2">

                <!-- Brand -->
                <div class="img-fluid text-dark">
                    <img src="../../assets/img/svg/venice.svg">
                </div>

            </div>
            <div class="col-4 col-md-3 col-lg-2">

                <!-- Brand -->
                <div class="img-fluid text-dark">
                    <img src="../../assets/img/svg/fitness-park.svg">
                </div>

            </div>
            <div class="col-4 col-md-3 col-lg-2">

                <!-- Brand -->
                <div class="img-fluid text-dark">
                    <img src="../../assets/img/svg/fit-life.svg">
                </div>

            </div>
            <div class="col-4 col-md-3 col-lg-2">

                <!-- Brand -->
                <div class="img-fluid text-dark">
                    <img src="../../assets/img/svg/venice.svg">
                </div>

            </div>
        </div>
    </div> <!-- / .container -->
</section>

<!-- PROCESS -->
<section class="pt-8 pt-md-11" id="anchor-step">
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-12 col-md-6">

                <!-- Preheading -->
                <h6 class="text-uppercase text-primary fw-bold">
                    Les étapes
                </h6>

                <!-- Heading -->
                <h2>
                    Chacun est unique : nous n’avons pas le même métabolisme, ni les mêmes besoins
                </h2>

                <!-- Text -->
                <p class="lead text-muted mb-6 mb-md-0">
                    Une prise en compte de l’individu et de son état de santé, et pas seulement de son assiette
                </p>

            </div>
            <div class="col-12 col-md-6 col-xl-5">

                <div class="row gx-0">
                    <div class="col-4">

                        <!-- Image -->
                        <div class="w-150 mt-9 p-1 bg-white shadow-lg" data-aos="fade-up" data-aos-delay="100"
                            data-aos-anchor="#anchor-step">
                            <img src="assets/img/origin/competition.png" class="img-fluid" alt="...">
                        </div>

                    </div>
                    <div class="col-4">

                        <!-- Image -->
                        <div class="w-150 p-1 bg-white shadow-lg" data-aos="fade-up" data-aos-anchor="#anchor-step">
                            <img src="assets/img/origin/nutrition.png" class="img-fluid" alt="...">
                        </div>

                    </div>
                    <div class="col-4 position-relative">

                        <!-- Image -->
                        <div class="w-150 mt-11 float-end p-1 bg-white shadow-lg" data-aos="fade-up"
                            data-aos-delay="150" data-aos-anchor="#anchor-step">
                            <img src="assets/img/origin/index2.png" class="img-fluid" alt="...">
                        </div>

                    </div>
                </div> <!-- / .row -->

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>

<!-- STEPS -->
<section class="pt-8 pb-9 pt-md-11 pb-md-13">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-auto col-md-12">

                        <!-- Step -->
                        <div class="row gx-0 align-items-center mb-md-5">
                            <div class="col-auto">

                                <a href="#!" class="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1">
                                    <span>1</span>
                                </a>

                            </div>
                            <div class="col">

                                <hr class="d-none d-md-block me-n7">

                            </div>
                        </div> <!-- / .row -->

                    </div>
                    <div class="col col-md-12 ms-n5 ms-md-0">

                        <!-- Heading -->
                        <h3>
                            Préparation
                        </h3>

                        <!-- Text -->
                        <p class="text-muted mb-6 mb-md-0">
                            Prise en compte des besoins
                        </p>

                    </div>
                </div> <!-- / .row -->
            </div>
            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-auto col-md-12">

                        <!-- Step -->
                        <div class="row gx-0 align-items-center mb-md-5">
                            <div class="col-auto">

                                <a href="#!" class="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1">
                                    <span>2</span>
                                </a>

                            </div>
                            <div class="col">

                                <hr class="d-none d-md-block me-n7">

                            </div>
                        </div> <!-- / .row -->

                    </div>
                    <div class="col col-md-12 ms-n5 ms-md-0">

                        <!-- Heading -->
                        <h3>
                            Planning
                        </h3>

                        <!-- Text -->
                        <p class="text-muted mb-6 mb-md-0">
                            Préparation du programme adapté à vos objectifs
                        </p>

                    </div>
                </div> <!-- / .row -->
            </div>
            <div class="col-12 col-md-4">
                <div class="row">
                    <div class="col-auto col-md-12">

                        <!-- Step -->
                        <div class="row gx-0 align-items-center mb-md-5">
                            <div class="col-auto">

                                <a href="#!" class="btn btn-sm btn-rounded-circle btn-gray-400 disabled opacity-1">
                                    <span>3</span>
                                </a>

                            </div>
                        </div> <!-- / .row -->

                    </div>
                    <div class="col col-md-12 ms-n5 ms-md-0">

                        <!-- Heading -->
                        <h3>
                            Résultats
                        </h3>

                        <!-- Text -->
                        <p class="text-muted mb-0">
                            Profitez de votre réussite !
                        </p>

                    </div>
                </div> <!-- / .row -->
            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>

<!-- SHAPE -->
<div class="position-relative">
    <div class="shape shape-bottom shape-fluid-x text-light">
        <svg viewBox="0 0 2880 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 28H0v28h2880V28h-720S1874 0 1440 0 720 28 720 28z" fill="currentColor" />
        </svg>
    </div>
</div>



<!-- TOGETHER -->
<section id="anchor-count" class="bg-light">
    <div class="container d-flex flex-column">
        <div class="row align-items-center justify-content-between min-vh-100 py-8 py-md-11">
            <div class="col-12 col-md-6 col-lg-5">

                <!-- Badge -->
                <span class="badge rounded-pill bg-primary-soft mb-3">
                    <span class="h6 text-uppercase">Votre coach</span>
                </span>

                <!-- Heading -->
                <h2>
                    Mon expertise physique et nutritionenl<br>
                    <span class="text-primary">pour vous acompagner</span>.
                </h2>

                <!-- Text -->
                <p class="fs-lg text-gray-700 mb-6">
                    Diplomé en Micro-nutrition et sportive professionel, je vous apporte ici des années de travail qui
                    m'ont permit de réaliser mes objectifs
                </p>

                <!-- Stats -->
                <div class="d-flex mb-8 mb-md-0">
                    <div class="pe-5">
                        <h3 class="mb-0">
                            <span data-countup='{"startVal": 0}' data-to="100" data-aos data-aos-id="countup:in"
                                data-aos-anchor="#anchor-count">0</span>%
                        </h3>
                        <p class="text-gray-700 mb-0">
                            Conseils
                        </p>
                    </div>
                    <div class="border-start border-gray-300"></div>
                    <div class="px-5">
                        <h3 class="mb-0">
                            <span data-countup='{"startVal": 0}' data-to="100" data-aos data-aos-id="countup:in"
                                data-aos-anchor="#anchor-count">0</span>%
                        </h3>
                        <p class="text-gray-700 mb-0">
                            Satisfaction
                        </p>
                    </div>
                    <div class="border-start border-gray-300"></div>
                    <div class="ps-5">
                        <h3 class="mb-0">
                            <span data-countup='{"startVal": 0}' data-to="100" data-aos data-aos-id="countup:in"
                                data-aos-anchor="#anchor-count">0</span>%
                        </h3>
                        <p class="text-gray-700 mb-0">
                            Réussite
                        </p>
                    </div>
                </div>

            </div>
            <div class="col-12 col-md-6">

                <!-- Images -->
                <div class="row" id="anchor-pricing">
                    <div class="col-6 mt-8 me-n5" data-aos="fade-up" data-aos-anchor="#anchor-count">

                        <img src="assets/img/photos/photo-10.jpg" alt="..." class="img-fluid mb-4 rounded">

                        <img src="assets/img/photos/photo-12.jpg" alt="..." class="img-fluid rounded">

                    </div>
                    <div class="col-6" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="#anchor-count">

                        <img src="assets/img/photos/photo-9.jpg" alt="..." class="img-fluid mb-4 rounded">

                        <img src="assets/img/photos/photo-11.jpg" alt="..." class="img-fluid rounded">

                    </div>
                </div> <!-- / .row -->

            </div>
        </div>
    </div>
</section>

<!-- PRICING -->
<section class="mt-n8">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="50" data-aos-anchor="#anchor-pricing">

                <!-- Card -->
                <div class="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
                    <div class="card-body">

                        <!-- Badge -->
                        <span class="badge rounded-pill bg-primary-desat badge-float badge-float-outside">
                            30€
                        </span>

                        <!-- Heading -->
                        <h4 class="fw-bold">
                            Préparation physique
                        </h4>

                        <!-- Text -->
                        <p class="text-muted">
                            Développer votre corps plus efficacement
                        </p>

                        <!-- Link -->
                        <a href="#!" class="fs-sm fw-bold text-decoration-none">
                            Commencez votre programme de 30 jours <i class="fe fe-arrow-right ms-3"></i>
                        </a>

                    </div>
                </div>

            </div>
            <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="100" data-aos-anchor="#anchor-pricing">

                <!-- Card -->
                <div class="card shadow-light-lg mb-6 mb-md-0 lift lift-lg">
                    <div class="card-body">

                        <!-- Badge -->
                        <span class="badge rounded-pill bg-primary-desat badge-float badge-float-outside">
                            15€
                        </span>

                        <!-- Heading -->
                        <h4 class="fw-bold">
                            Programme nutrition
                        </h4>

                        <!-- Text -->
                        <p class="text-muted">
                            Améliorer vos apports nutritionels
                        </p>

                        <!-- Link -->
                        <a href="#!" class="fs-sm fw-bold text-decoration-none">
                            Commencez votre programme 3 mois <i class="fe fe-arrow-right ms-3"></i>
                        </a>

                    </div>
                </div>

            </div>
            <div class="col-12 col-md-4" data-aos="fade-up" data-aos-delay="150" data-aos-anchor="#anchor-pricing">

                <!-- Card -->
                <div class="card shadow-light-lg lift lift-lg">
                    <div class="card-body">

                        <!-- Badge -->
                        <span class="badge rounded-pill bg-primary-desat badge-float badge-float-outside">
                            100€
                        </span>

                        <!-- Heading -->
                        <h4 class="fw-bold">
                            Programme personnalisé
                        </h4>

                        <!-- Text -->
                        <p class="text-muted">
                            Une programme spécialement concu pour vous et vos besoins !
                        </p>

                        <!-- Link -->
                        <a href="#!" class="fs-sm fw-bold text-decoration-none">
                            Découvrir votre profil <i class="fe fe-arrow-right ms-3"></i>
                        </a>

                    </div>
                </div>

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>





<!-- IMAGE -->
<section data-jarallax data-speed=".8" class="py-14 py-lg-16 jarallax"
    style="background-image: url(assets/img/origin/index1.jpg);">

    <!-- Shape -->
    <div class="shape shape-top shape-fluid-x text-white">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h2880v125h-720L720 250H0V0z" fill="currentColor" />
        </svg>
    </div>

    <!-- Shape -->
    <div class="shape shape-bottom shape-fluid-x text-light">
        <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M720 125L2160 0h720v250H0V125h720z" fill="currentColor" />
        </svg>
    </div>

</section>

<!-- ARTICLES -->
<section class="pt-7 pt-md-10 bg-light">
    <div class="container">
        <div class="row align-items-center mb-5">
            <div class="col-12 col-md">

                <!-- Heading -->
                <h3 class="mb-0">
                    Les derniers articles
                </h3>

                <!-- Text -->
                <p class="mb-0 text-muted">
                    à ne pas manquer !
                </p>

            </div>
            <div class="col-12 col-md-auto">

                <!-- Button -->
                <a routerLink="../../articles" class="btn btn-sm btn-outline-gray-300 d-none d-md-inline">
                    Voir tous
                </a>

            </div>
        </div> <!-- / .row -->
        <div class="row">
            <div class="col-12 col-md-6 col-lg-4 d-flex" *ngFor="let post of lastThreePosts">

                <!-- Card -->
                <div class="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg">

                    <!-- Image -->
                    <a class="card-img-top" routerLink="../{{post?.id}}">

                        <!-- Image -->
                        <img src="{{globals.IMG_URL}}{{post.file}}" alt="..." class="card-img-top">

                        <!-- Shape -->
                        <div class="position-relative">
                            <div class="shape shape-bottom shape-fluid-x text-white">
                                <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" fill="currentColor" />
                                </svg>
                            </div>
                        </div>

                    </a>

                    <!-- Body -->
                    <a class="card-body" routerLink="../{{post?.id}}">

                        <!-- Heading -->
                        <h3>
                            {{post?.title}}
                        </h3>

                        <!-- Text -->
                        <p class="mb-0 text-muted">
                            {{post?.preview}}
                        </p>

                    </a>

                    <!-- Meta -->
                    <a class="card-meta mt-auto" routerLink="../{{post?.id}}">

                        <!-- Divider -->
                        <hr class="card-meta-divider">

                        <!-- Avatar -->
                        <div class="avatar avatar-sm me-2">
                            <img src="assets/img/avatars/avatar-1.jpg" alt="..." class="avatar-img rounded-circle">
                        </div>

                        <!-- Author -->
                        <h6 class="text-uppercase text-muted me-2 mb-0">
                            Charlotte Loussouam
                        </h6>

                        <!-- Date -->
                        <p class="h6 text-uppercase text-muted mb-0 ms-auto">
                            <time>{{post?.createdAt | date: 'dd/MM/yyyy'}}</time>
                        </p>

                    </a>

                </div>

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>