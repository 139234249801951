<section class="py-10 py-md-14 overlay overlay-black overlay-60 bg-cover"
    style="background-image: url(assets/img/covers/cover-3.jpg);">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">

                <!-- Heading -->
                <h1 class="display-2 fw-bold text-white">
                    Contactez-moi
                </h1>

                <!-- Text -->
                <p class="lead text-white-75 mb-0">
                    Demande personnalisé, conseils, devis sur mesure
                </p>

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>

<!-- SHAPE -->
<div class="position-relative">
    <div class="shape shape-bottom shape-fluid-x text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
        </svg>
    </div>
</div>



<!-- FORM -->
<section class="pt-8 pt-md-11 pb-8 pb-md-14">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">

                <!-- Heading -->
                <h2 class="fw-bold">
                    Contactez-moi
                </h2>

                <!-- Text -->
                <p class="fs-lg text-muted mb-7 mb-md-9">
                    Demande personnalisé, conseils, devis sur mesure, je ferai au mieux pour répondre à vos attentes.
                </p>

            </div>
        </div> <!-- / .row -->
        <div class="row justify-content-center">
            <div class="col-12 col-md-12 col-lg-10">

                <!-- Form -->
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <div class="form-group mb-5">

                                <!-- Label -->
                                <label class="form-label" for="contactName">
                                    Nom
                                </label>

                                <!-- Input -->
                                <input class="form-control" id="contactName" type="text" placeholder="Votre nom"
                                    formControlName="name">
                                <div *ngIf="contactForm.controls['name'].invalid && (contactForm.controls['name'].dirty || contactForm.controls['name'].touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="contactForm.controls['name'].errors?.maxlength">
                                        255 caracteres maximum.
                                    </div>
                                    <div *ngIf="contactForm.controls['name'].errors?.required">
                                        Votre nom est obligatoire !
                                    </div>
                                    <div *ngIf="contactForm.controls['name'].errors?.pattern">
                                        Lettres uniquement.
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <div class="form-group mb-5">

                                <!-- Label -->
                                <label class="form-label" for="contactEmail">
                                    Email
                                </label>

                                <!-- Input -->
                                <input class="form-control" id="contactEmail" type="email"
                                    placeholder="hello@domain.com" formControlName="email">
                                <div *ngIf="contactForm.controls['email'].invalid && (contactForm.controls['email'].dirty || contactForm.controls['email'].touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="contactForm.controls['email'].errors?.maxlength">
                                        255 caracteres maximum.
                                    </div>
                                    <div *ngIf="contactForm.controls['email'].errors?.required">
                                        Votre email est obligatoire !
                                    </div>
                                    <div *ngIf="contactForm.controls['email'].errors?.email">
                                        Mauvais format (email@email.mail).
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> <!-- / .row -->
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group mb-7 mb-md-9">

                                <!-- Label -->
                                <label class="form-label" for="contactMessage">
                                    Votre demande
                                </label>

                                <!-- Input -->
                                <textarea class="form-control" id="contactMessage" rows="5"
                                    placeholder="Que puis-je pour vous ?" formControlName="message"></textarea>

                                <div *ngIf="contactForm.controls['message'].invalid && (contactForm.controls['message'].dirty || contactForm.controls['message'].touched)"
                                    class="alert alert-danger">
                                    <div *ngIf="contactForm.controls['message'].errors?.maxlength">
                                        3000 caracteres maximum.
                                    </div>
                                    <div *ngIf="contactForm.controls['message'].errors?.required">
                                        Votre message est obligatoire.
                                    </div>
                                    <div *ngIf="contactForm.controls['message'].errors?.pattern">
                                        Chiffres et Lettres uniquement.
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div> <!-- / .row -->
                    <div class="row justify-content-center">
                        <div *ngIf="mailSend" class="alert alert-success text-center">
                            Votre email a bien été envoyé, merci !
                        </div>
                        <div class="col-auto">
                            <!-- Submit -->
                            <button type="submit" value="Send" class="btn btn-primary lift">
                                Envoyer
                            </button>

                        </div>
                    </div> <!-- / .row -->
                </form>

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>