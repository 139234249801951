<section class="py-10 py-md-14 overlay overlay-black overlay-60 bg-cover"
    style="background-image: url(assets/img/origin/charlotte-accueil.png);">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">

                <!-- Heading -->
                <h1 class="display-2 fw-bold text-white">
                    Le coach
                </h1>

                <!-- Text -->
                <p class="lead text-white-75 mb-0">
                    Mon parcours
                </p>

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>

<!-- SHAPE -->
<div class="position-relative">
    <div class="shape shape-bottom shape-fluid-x text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
        </svg>
    </div>
</div>

<section class="py-8 py-md-11 border-bottom">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">

                <!-- Headin -->
                <h1 class="display-2">
                    Déterminée et passionée
                </h1>

                <!-- Text -->
                <p class="lead text-muted mb-7 mb-md-9">
                    Ce mode de vie est une passion pour moi et pouvoir la partager
                </p>

            </div>
        </div> <!-- / .row -->
        <div class="row">
            <div class="col-12">

                <!-- Image -->
                <div class="row gx-4">
                    <div class="col-4">

                        <img class="img-fluid rounded shadow-lg" src="assets/img/origin/charlotte1.png" alt="...">

                    </div>
                    <div class="col-3">

                        <img class="img-fluid rounded shadow-lg mb-4" src="assets/img/origin/charlotte2.png" alt="...">

                        <img class="img-fluid rounded shadow-lg" src="assets/img/origin/charlotte3.png" alt="...">

                    </div>
                    <div class="col-5">

                        <div class="row gx-5 mb-4">
                            <div class="col-5">

                                <img class="img-fluid rounded shadow-lg" src="assets/img/origin/charlotte4.png"
                                    alt="...">

                            </div>
                            <div class="col-7">

                                <img class="img-fluid rounded shadow-lg" src="assets/img/origin/charlotte5.png"
                                    alt="...">

                            </div>
                        </div> <!-- / .row -->

                        <img class="img-fluid rounded shadow-lg" src="assets/img/origin/charlotte6.png" alt=" ...">

                    </div>
                </div>

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->