import { Pipe, PipeTransform } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Chachou Life Style';

  constructor(private router: Router) {}

  ngOnInit() {
    this.loadScrip('assets/js/vendor.bundle.js');
    this.loadScrip('assets/js/theme.bundle.js');
    this.loadScrip('https://api.mapbox.com/mapbox-gl-js/v0.53.0/mapbox-gl.js');
  }

  // Rechargement des scripts à chaque changement de routes
  loadScrip(src: string) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        let node = document.createElement('script');
        node.src = src;
        node.type = 'text/javascript';
        node.async = true;
        document.getElementsByTagName('head')[0].appendChild(node);
      }
    });
  }
}

// Pipe pour découper un string et remplacer la fin par notre argument
@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args: any[]): string {
    const limit = args.length > 0 ? parseInt(args[0], 10) : 20;
    const trail = args.length > 1 ? args[1] : '...';
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}

// Pipe pour autoriser les styles CSS avec les brackets
@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(style: string) {
    return this.sanitizer.bypassSecurityTrustStyle(style);
  }
}
