<section class="py-10 py-md-14 overlay overlay-black overlay-60 bg-cover"
    style="background-image: url(assets/img/origin/homme-sport1.png);">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8 text-center">

                <!-- Heading -->
                <h1 class="display-2 fw-bold text-white">
                    Mes programmes
                </h1>

                <!-- Text -->
                <p class="lead text-white-75 mb-0">
                    Demande personnalisé, conseils, devis sur mesure
                </p>

                <!-- Button -->
                <!-- <a href="#!" class="btn btn-primary shadow lift">
                    Découvrir votre programme personnalisé
                </a> -->

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>

<!-- SHAPE -->
<div class="position-relative">
    <div class="shape shape-bottom shape-fluid-x text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="currentColor" />
        </svg>
    </div>
</div>
<!-- ARTICLES -->
<section class="pt-7 pt-md-10">
    <div class="container">
        <div class="row">
            <div class="col-12">

                <!-- Heading -->
                <h3 class="mb-0">
                    Programme musculaire
                </h3>

                <!-- Text -->
                <p class="mb-5 text-muted">
                    On part de là !
                </p>
            </div>
        </div> <!-- / .row -->
        <div class="beforeContent" *ngIf="programs == null">
        </div>
        <div class="row">
            <div class="col-12 col-md-6 d-flex" *ngFor="let program of programs">

                <!-- Card -->
                <div class="card mb-6 shadow-light-lg lift lift-lg">

                    <!-- Image -->
                    <a class="card-img-top" href="#!">
                        <!-- Image -->
                        <img src="{{globals.IMG_URL}}{{ program!.file }}" alt="..." class="card-img-top">

                        <!-- Shape -->
                        <div class="position-relative">
                            <div class="shape shape-bottom shape-fluid-x text-white">
                                <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" fill="currentColor" />
                                </svg>
                            </div>
                        </div>

                    </a>

                    <!-- Body -->
                    <a class="card-body" href="#!">

                        <!-- Heading -->
                        <h3>
                            {{ program!.title }}
                        </h3>

                        <!-- Text -->
                        <p class="mb-0 text-muted" [innerHTML]="program.content | truncate:[200, '...']">

                        </p>

                    </a>

                    <a class="gumroad-button text-center" href="https://gumroad.com/l/oPNCL?wanted=true"
                        target="_blank">Achetez {{ program!.price }} €</a>

                </div>

            </div>

            <!-- Heading -->
            <h3 class="mb-0">
                Programme nutrition
            </h3>

            <!-- Text -->
            <p class="mb-5 text-muted">
                On part de là !
            </p>

            <div class="col-12 col-md-6 col-lg-4 d-flex">

                <!-- Card -->
                <div class="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg">

                    <!-- Image -->
                    <a class="card-img-top" href="#!">

                        <!-- Image -->
                        <img src="assets/img/photos/photo-6.jpg" alt="..." class="card-img-top">

                        <!-- Shape -->
                        <div class="position-relative">
                            <div class="shape shape-bottom shape-fluid-x text-white">
                                <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" fill="currentColor" />
                                </svg>
                            </div>
                        </div>

                    </a>

                    <!-- Body -->
                    <a class="card-body" href="#!">

                        <!-- Heading -->
                        <h3>
                            Landkit is Moving to an Office In Austin, TX
                        </h3>

                        <!-- Text -->
                        <p class="mb-0 text-muted">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec condimentum quam.
                        </p>

                    </a>

                    <a class="gumroad-button text-center" href="https://gumroad.com/l/oPNCL?wanted=true"
                        target="_blank">Achetez 50€</a>

                </div>

            </div>
            <div class="col-12 col-md-6 col-lg-4 d-flex">

                <!-- Card -->
                <div class="card mb-6 mb-lg-0 shadow-light-lg lift lift-lg">

                    <!-- Image -->
                    <a class="card-img-top" href="#!">

                        <!-- Image -->
                        <img src="assets/img/photos/photo-4.jpg" alt="..." class="card-img-top">

                        <!-- Shape -->
                        <div class="position-relative">
                            <div class="shape shape-bottom shape-fluid-x text-white">
                                <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" fill="currentColor" />
                                </svg>
                            </div>
                        </div>

                    </a>

                    <!-- Body -->
                    <a class="card-body" href="#!">

                        <!-- Heading -->
                        <h3>
                            Are Hot Desks Great for Digital Nomads?
                        </h3>

                        <!-- Text -->
                        <p class="mb-0 text-muted">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec condimentum quam.
                        </p>

                    </a>

                    <a class="gumroad-button text-center" href="https://gumroad.com/l/oPNCL?wanted=true"
                        target="_blank">Achetez 50€</a>

                </div>

            </div>
            <div class="col-12 col-md-6 col-lg-4 d-flex">

                <!-- Card -->
                <div class="card mb-6 mb-md-0 shadow-light-lg lift lift-lg">

                    <!-- Image -->
                    <a class="card-img-top" href="#!">

                        <!-- Image -->
                        <img src="assets/img/photos/photo-5.jpg" alt="..." class="card-img-top">

                        <!-- Shape -->
                        <div class="position-relative">
                            <div class="shape shape-bottom shape-fluid-x text-white">
                                <svg viewBox="0 0 2880 480" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2160 0C1440 240 720 240 720 240H0v240h2880V0h-720z" fill="currentColor" />
                                </svg>
                            </div>
                        </div>

                    </a>

                    <!-- Body -->
                    <a class="card-body" href="#!">

                        <!-- Heading -->
                        <h3>
                            Coffee Can Keep You Going, But At What Cost?
                        </h3>

                        <!-- Text -->
                        <p class="mb-0 text-muted">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis nec condimentum quam.
                        </p>

                    </a>

                    <a class="gumroad-button text-center" href="https://gumroad.com/l/oPNCL?wanted=true"
                        target="_blank">Achetez 50€</a>
                </div>

            </div>

        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>