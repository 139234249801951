<!-- CTA -->
<section class="pt-7 pt-md-10 bg-dark">
    <div class="container py-6 py-md-8 border-top border-bottom border-gray-300">
        <div class="row align-items-center">
            <div class="col-12 col-md">

                <!-- Heading -->
                <h3 class="mb-1 fw-bold">
                    Suivez-moi
                </h3>

                <!-- Text -->
                <p class="fs-lg text-muted mb-6 mb-md-0">
                    Vous receverez mes nouveaux programmes !
                </p>

            </div>
            <div class="col-12 col-md-5">

                <!-- Form -->
                <form>
                    <div class="row">
                        <div class="col">

                            <!-- Input -->
                            <input type="email" class="form-control" placeholder="Votre email">

                        </div>
                        <div class="col-auto ms-n5">

                            <!-- Button -->
                            <button class="btn btn-primary lift" type="submit">
                                Inscrivez-vous
                            </button>

                        </div>
                    </div> <!-- / .row -->
                </form>

            </div>
        </div> <!-- / .row -->
    </div> <!-- / .container -->
</section>

<!-- CURVE -->
<div class="position-relative mt-n16">
    <div class="shape shape-bottom shape-fluid-x text-dark">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48h2880V0h-720C1442.5 52 720 0 720 0H0v48z" fill="#564a4a" />
        </svg>
    </div>
</div>

<!-- FOOTER -->
<section class="pt-16 bg-dark">
    <footer class="py-8 py-md-11 bg-dark border-top border-gray-800-50">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-4 col-lg-3">

                    <!-- Brand -->
                    <img src="./assets/img/origin/logo.svg" alt="..." class="footer-brand img-fluid mb-2">

                    <!-- Text -->
                    <p class="text-gray-700 mb-2">
                        Pour avoir ce que tu n'as jamais eu..
                    </p>

                    <!-- Social -->
                    <ul class="list-unstyled list-inline list-social mb-6 mb-md-0">
                        <li class="list-inline-item list-social-item me-3">
                            <a href="#!" class="text-decoration-none">
                                <img src="./assets/img/icons/social/instagram.svg" class="list-social-icon" alt="...">
                            </a>
                        </li>
                        <li class="list-inline-item list-social-item me-3">
                            <a href="#!" class="text-decoration-none">
                                <img src="./assets/img/icons/social/facebook.svg" class="list-social-icon" alt="...">
                            </a>
                        </li>
                        <li class="list-inline-item list-social-item me-3">
                            <a href="#!" class="text-decoration-none">
                                <img src="./assets/img/icons/social/twitter.svg" class="list-social-icon" alt="...">
                            </a>
                        </li>
                        <li class="list-inline-item list-social-item">
                            <a href="#!" class="text-decoration-none">
                                <img src="./assets/img/icons/social/pinterest.svg" class="list-social-icon" alt="...">
                            </a>
                        </li>
                    </ul>

                </div>
                <div class="col-6 col-md-4 col-lg-2">

                    <!-- Heading -->
                    <h6 class="fw-bold text-uppercase text-gray-700">
                        Programmes
                    </h6>

                    <!-- List -->
                    <ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                        <li class="mb-3">
                            <a routerLink="programmes" class="text-reset">
                                Compétition
                            </a>
                        </li>
                        <li class="mb-3">
                            <a routerLink="programmes" class="text-reset">
                                Nutrition
                            </a>
                        </li>
                        <li class="mb-3">
                            <a routerLink="programmes" class="text-reset">
                                personnalisés
                            </a>
                        </li>
                    </ul>

                </div>
                <div class="col-6 col-md-4 col-lg-2">

                    <!-- Heading -->
                    <h6 class="fw-bold text-uppercase text-gray-700">
                        Articles
                    </h6>

                    <!-- List -->
                    <ul class="list-unstyled text-muted mb-6 mb-md-8 mb-lg-0">
                        <li class="mb-3">
                            <a routerLink="articles" class="text-reset">
                                Alimentation
                            </a>
                        </li>
                        <li class="mb-3">
                            <a routerLink="articles" class="text-reset">
                                Body-building
                            </a>
                        </li>
                        <li class="mb-3">
                            <a routerLink="articles" class="text-reset">
                                Divers
                            </a>
                        </li>
                    </ul>

                </div>
                <div class="col-6 col-md-4 offset-md-4 col-lg-2 offset-lg-0">

                    <!-- Heading -->
                    <h6 class="fw-bold text-uppercase text-gray-700">
                        Transformations
                    </h6>

                    <!-- List -->
                    <ul class="list-unstyled text-muted mb-0">
                        <li class="mb-3">
                            <a routerLink="transformations" class="text-reset">
                                Avant-Après
                            </a>
                        </li>
                        <li class="mb-3">
                            <a routerLink="transformations" class="text-reset">
                                Témoignages
                            </a>
                        </li>

                    </ul>

                </div>
                <div class="col-6 col-md-4 col-lg-2">

                    <!-- Heading -->
                    <h6 class="fw-bold text-uppercase text-gray-700">
                        A propos
                    </h6>

                    <!-- List -->
                    <ul class="list-unstyled text-muted mb-0">
                        <li class="mb-3">
                            <a routerLink="coach" class="text-reset">
                                Qui suis-je?
                            </a>
                        </li>
                        <li class="mb-3">
                            <a routerLink="coach" class="text-reset">
                                CGV
                            </a>
                        </li>
                    </ul>

                </div>
            </div> <!-- / .row -->
        </div> <!-- / .container -->
    </footer>
</section>